import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';
import ModelManager from 'src/common/ModelManager';
import saveList from 'src/hooks/useSaveList';
import siteService from 'src/services/siteService';

const DefaultLayout = dynamic(() => import('src/layout/DefaultLayout'));
const BannerTop = dynamic(() => import('src/components/HomePage/BannerTop'), {
    ssr: true,
});

function HomePage({ data, listSite }) {
    saveList(listSite);
    const [defaultAcademy, setDefaultAcademy] = useState({});
    const [Reason, setReason] = useState(null);
    const [Intro, setIntro] = useState(null);
    const [WhatWeDo, setWhatWeDo] = useState(null);
    const [BookTrial, setBookTrial] = useState(null);
    const [FootballBegining, setFootballBegining] = useState(null);
    const [FootballFun, setFootballFun] = useState(null);
    const [InstaBox, setInstaBox] = useState(null);

    useEffect(() => {
        setDefaultAcademy(ModelManager?.getLocation());
    }, []);

    useEffect(() => {
        setIntro(
            dynamic(() => import('src/components/HomePage/Intro.js'), {
                ssr: true,
            }),
        );
        setReason(dynamic(() => import('src/components/HomePage/Reason')));
        setWhatWeDo(dynamic(() => import('src/components/HomePage/WhatWeDo')));
        setBookTrial(dynamic(() => import('src/components/Booking/BookTrial')));
        setFootballBegining(
            dynamic(() => import('src/components/HomePage/FootballBegining')),
        );
        setFootballFun(
            dynamic(() => import('src/components/HomePage/FootballFun')),
        );
        setInstaBox(dynamic(() => import('src/components/HomePage/InstaBox')));
    }, [defaultAcademy]);

    return (
        <DefaultLayout seo={data?.seoMeta || {}}>
            <BannerTop bannerTop={data?.bannerTop || {}} />
            <div className="intro-homepage">
                {Intro && <Intro intro={data?.homeIntro?.cfg_value || []} />}
            </div>

            {WhatWeDo && (
                <WhatWeDo
                    whatWeDo={data?.whatWeDo || {}}
                    testimonial={data?.testimonial || {}}
                    gallery={data?.gallery || {}}
                    gallery2={data?.gallery2 || {}}
                />
            )}

            {Reason && <Reason reason={data?.reason?.cfg_value || []} />}

            {BookTrial && (
                <BookTrial
                    parentFb={data?.parentFb || {}}
                    site={defaultAcademy}
                />
            )}

            <div className="fb-begin-homepage">
                {FootballBegining && (
                    <FootballBegining
                        footballBegining={data?.footballBegining || {}}
                    />
                )}
            </div>
            {FootballFun && (
                <FootballFun footballFun={data?.footballFun || {}} />
            )}
            {InstaBox && <InstaBox instaFeed={data?.instaFeed || {}} />}
        </DefaultLayout>
    );
}
//

export async function getServerSideProps({ req }) {
    const requestHeaders = new Headers(req.headers)

    siteService.forwardHeaders({
        ['x-forwarded-for']: requestHeaders.get('x-forwarded-for') || 'test',
    });

    return await Promise.all([
        siteService.getHome(),
        siteService.getListSite(),
    ]).then((values) => {
        return {
            props: {
                data: values[0].data.data,
                listSite: values[1].data.data.lstSite
            },
        };
    });
}

export default HomePage;
